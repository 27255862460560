<template lang="pug">
.om-wizard
  segmented-top(:percent="percent" @skip="onSkip")
  .om-wizard-body
    .container(v-if="step !== 'recommendation'")
      component(
        ref="currentStep"
        :is="step"
        @loaderAnimationEnd="isRecommendationLoading = false"
        @change="setResponse($event)"
        @loaded="setLoaded($event)"
        @loading="setLoading($event)"
      )
    component(
      v-else
      ref="currentStep"
      :is="step"
      @loaderAnimationEnd="isRecommendationLoading = false"
      @change="setResponse($event)"
      @loaded="setLoaded($event)"
    )
  bottom(
    v-if="showBottom && !isRecommendationLoading"
    @next="onNext"
    @back="onBack"
    @skip="onSkip"
    :disableNextButton="!loaded"
    :loading="loading"
  )
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex';
  import { track } from '@/services/xray';
  import { accountSetupRouteNames } from '@/router/routes/accountSetupRoutes';
  import { getAccountIdFromCookie } from '@/util';
  import SET_PROFILE_KEY from '@/graphql/SetProfileKey.gql';
  import { WIZARD_LAST_PAGE_KEY } from '@/config/constants';
  import { isAutoPersonalizeEnabled } from '@/utils/features';
  import { WIZARD_NAME } from './consts';
  import { percentage } from './flow';
  import navigationMixin from './navigation';
  import * as SmartWizardScreens from './pages';
  import smartWizardMixin from './smartWizard';

  export default {
    components: {
      SegmentedTop: () => import('@/components/Wizard/SegmentedTop.vue'),
      Bottom: () => import('@/components/Wizard/Bottom.vue'),
      ...SmartWizardScreens,
      Top: () => import('@/components/Wizard/Top.vue'),
    },
    mixins: [navigationMixin, smartWizardMixin],
    data() {
      return {
        isRecommendationLoading: true,
        percentage: [],
        response: null,
        loaded: true,
        loading: false,
      };
    },
    computed: {
      ...mapGetters(['accountFeatures']),
      percent() {
        return this.percentage[this.step];
      },
      showBottom() {
        return this.$route.name.includes('wizard');
      },
    },
    watch: {
      accountFeatures: {
        handler() {
          this.percentage = percentage(isAutoPersonalizeEnabled(this.accountFeatures));
        },
        deep: true,
        immediate: true,
      },
      '$route.fullPath': {
        async handler() {
          await this.saveWizardLastPage();
        },
        deep: true,
        immediate: true,
      },
    },
    created() {
      this.isRecommendationLoading = this.step?.includes('recommendation');
    },
    mounted() {
      this.checkUrl();
    },
    methods: {
      ...mapMutations(['setProfileKey']),
      checkUrl() {
        const { wizard } = this.$route.params;
        if (wizard && wizard !== WIZARD_NAME) {
          this.$router.replace({
            name: accountSetupRouteNames.BRANCHING,
          });
        }
      },
      setResponse(response) {
        this.response = response;
      },
      setLoaded(loaded) {
        this.loaded = loaded;
      },
      async onSkip(params = {}) {
        this.$store.dispatch('finishOnboarding', null);
        track('wizard-skip-templates', { page: this.step });
        await this.updateWizardPreferences({ skip: true });
        await this.saveWizardLastPage();
        this.$router.push({ name: 'dashboard', params });
      },
      onNext() {
        track('wizard-next', { page: this.step });
        const nextPageMethod = this.$refs.currentStep?.nextPage;
        if (!nextPageMethod) {
          console.log('implement next page method in:', this.step);
          return;
        }
        if (this.response) {
          this.updateWizardPreferences(this.response);
        }
        nextPageMethod();
      },
      onBack(path) {
        this.saveWizardLastPage(path);
      },
      setLoading(value) {
        this.loading = value;
      },
      async saveWizardLastPage(path) {
        let lastPath = path;

        if (!lastPath) {
          const { pathname = '', search = '' } = window.location ?? '';
          lastPath = `${pathname}${search}`;
        }

        const lastPageData = {
          key: WIZARD_LAST_PAGE_KEY,
          value: `${getAccountIdFromCookie()}:${lastPath}`,
        };

        await Promise.all([
          this.setProfileKey(lastPageData),
          this.$apollo.mutate({
            mutation: SET_PROFILE_KEY,
            variables: lastPageData,
          }),
        ]);
      },
    },
  };
</script>
<style lang="sass">
  .om-wizard-body > .container
    padding: 3rem 0
</style>
