<template lang="pug">
.om-wizard-brandkit.pb-6.mb-6
  .text-center
    wizard-title.mb-3 {{ $t('onboarding.customTheme.customize.title') }}
    om-body-text(bt400md) {{ $t('onboarding.customTheme.customize.description') }}
  .row(:style="'margin-top: 1.5rem'")
    .col-9
      .wizard-theme-item
        .wizard-theme-head
          .dot(v-for="dot in 3")
        .wizard-theme-content
          transition(name="fade")
            template-frame(
              @observable="addObservable($event.$el)"
              v-if="selectedMainTemplate"
              @inited="updateDimensions"
              :dimensions="boxDimensions"
              allowSsr
              :template="selectedMainTemplate"
              @contentLoaded="onContentLoaded"
              :endpoint="'themekit-preview'"
              :payload="{ themeKit: payload, baseTheme: true }"
              clearOnChange
            )
    .col-3
      div(v-show="")
        om-heading.text-left.mb-2(h6) {{ $t('onboarding.customTheme.customize.labels.name') }}
        om-input#brandkit-name.mb-4(v-model="name" :error="$v.name.$error")
      om-heading.text-left.mb-2(h6) {{ $t('onboarding.customTheme.customize.labels.language') }}
      LanguageChooserDropdown.mb-4(@change="language = $event")
      template(v-if="currentLogo")
        om-heading.text-left.mb-2(h6) {{ $t('onboarding.customTheme.customize.labels.logo') }}
        #brandkit-logo.mb-4.d-flex.align-items-center
          .brandkit-logo-preview-box.d-flex.align-items-center.justify-content-center.flex-grow-1.mr-2.cursor-pointer(
            @click="showImageManager"
          )
            img(:src="currentLogo")
          om-button(
            v-if="isOriginalLogo"
            small
            iconOnly
            ghost
            icon="upload"
            @click="showImageManager"
          )
          om-button.btn-logo-delete(
            v-else
            small
            iconOnly
            ghost
            icon="trash-alt"
            @click="clearLogo"
          )
      om-heading.text-left.mb-2(h6) {{ $t('onboarding.customTheme.customize.labels.color') }}
      brand-kit-palette.mb-3(
        v-if="mainColor"
        ref="brandKitPalette"
        :color="mainColor"
        :themeColors="themeColors"
        @mainColor="mainColor = $event"
        @secondaryColors="themeColors = $event"
        @backdropVisbility="onBackdropVisibilityChange"
      )
      om-heading.text-left.mb-2(h6) {{ $t('onboarding.customTheme.customize.labels.font') }}
      brand-font.mb-4(ref="primaryFont" :value="selectedFont" @select="selectFont($event)")
      om-heading.text-left.mb-2(h6) {{ $t('onboarding.customTheme.customize.labels.secondaryFont') }}
      brand-font.mb-4(
        ref="secondaryFont"
        :value="selectedSecondaryFont"
        @select="selectSecondaryFont($event)"
      )
      om-heading.text-left.mb-2(h6) {{ $t('onboarding.customTheme.customize.labels.corners') }}
      brand-kit-radius-elements.brand-kit-radiuses(
        @select="selectRadiusType($event)"
        :value="selectedRadius ? `${selectedRadius}-type` : null"
      )
      om-button(
        secondary
        data-track="wizard-brandKit-resetTheme"
        block
        @click="reset()"
        :style="'margin-top: 1rem'"
      ) {{ $t('onboarding.customTheme.customize.buttons.reset') }}
  custom-font-upload-v2
  transition(name="fade")
    UserImages(@use="onUseImage" @upload="$apollo.queries.images.refetch()")
  .om-color-picker-backdrop(v-if="showBackdrop" @click.stop="onBackdropClick")
</template>
<script>
  import CustomFontUploadV2 from '@/editor/components/modals/CustomFontUploadV2.vue';
  import TemplateFrame from '@/components/Template/TemplateFrame.vue';
  import BrandKitRadiusElements from '@/editor/components/sidebar/RadiusElements.vue';
  import BrandFont from '@/components/Wizard/BrandFont.vue';
  import BrandKitPalette from '@/components/Wizard/BrandKitPalette.vue';
  import LanguageChooserDropdown from '@/components/TemplateChooser/components/LanguageChooserDropdown.vue';
  import personalizeMixin from '@/mixins/personalize';

  export default {
    components: {
      TemplateFrame,
      BrandKitRadiusElements,
      BrandFont,
      BrandKitPalette,
      CustomFontUploadV2,
      LanguageChooserDropdown,
      UserImages: () => import('@/components/Wizard/UserImages.vue'),
    },
    mixins: [personalizeMixin],
    created() {
      this.$emit('loaded', this.loaded);
      // preload loading animation
      import(`@/lottie/${this.lottieLoading}.json`);
      document.addEventListener('click', () => {
        this.hideColorPicker();
      });
    },
  };
</script>
<style lang="sass">
  @import '@/sass/pages/_wizard.sass'
  @import "@/sass/variables/_colors.sass"
  .wizard-theme
    &-item
      height: 100%
    &-content
      width: 100%
      .template-thumbnail-box
        border-top-left-radius: 0
        border-top-right-radius: 0
        height: 100%
  .brand-kit-radiuses
    justify-content: space-between
    .radius-elements
      flex: 0 0 calc(25% - 8px)
      margin: 0
  .wizard-radius-container
    justify-content: space-between
    margin: 0 -4px
    .radius-elements
      margin: 0 4px
  .om-color-picker-backdrop
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 1100
  #brandkit-logo
    .btn
      height: max-content
  .brandkit-logo-preview-box
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMElEQVQ4T2N89uzZfwY8QFJSEp80A+OoAcMiDP7//483HTx//hx/Ohg1gIFx6IcBALl+VXknOCvFAAAAAElFTkSuQmCC")
    max-width: 15rem
    height: 3.75rem
    border: 1px solid $om-gray-300
    border-radius: 4px
    padding: .25rem
    img
      width: 100%
      height: auto
      max-height: 3.25rem
      object-fit: contain
  .om-wizard
    .brand-modal-body
      .user-images-body
        .om-progressive-image
          height: 100%
</style>
