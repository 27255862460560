import { get } from 'lodash-es';
import { mapMutations, mapGetters, mapState } from 'vuex';
import previewParentMixin from '@/mixins/previewParent';
import ssrMixin from '@/mixins/ssr';
import observableCollectionMixin from '@/mixins/observableCollection';
import { brandkitTracker } from '@/services/userInteractionTracker/tracker';
import { track } from '@/services/xray';
import { getPaletteColors } from '@om/template-properties/src/getPaletteColors';
import { getNameFromURL } from '@om/template-properties/src/imageReplace';
import UPSERT_CUSTOM_THEME from '@/graphql/UpsertCustomTheme.gql';
import GET_CUSTOM_THEMES from '@/graphql/GetCustomThemes.gql';
import GET_AUTO_LOGO from '@/graphql/GetAutoLogo.gql';
import { MODAL_NAME } from '@/components/Wizard/UserImages.vue';
import logoMixin from '@/mixins/logo';
import sharedMixin from '../views/Wizard/shared';
import navigationMixin from '../views/Wizard/navigation';
import { AUTO_PERSONALIZE } from '../views/Wizard/flow';

const THEME_NAME_FALLBACK = 'My theme 1';

export default {
  mixins: [
    sharedMixin,
    navigationMixin,
    previewParentMixin,
    ssrMixin,
    observableCollectionMixin,
    logoMixin,
  ],
  apollo: {
    customThemes: {
      query: GET_CUSTOM_THEMES,
      variables: {
        resolveMainTemplate: true,
      },
      update({ themes }) {
        const baseThemeId = this.$route.query?.baseThemeId;
        const selectedTheme = this.$route.query?.theme;
        const customTheme =
          themes?.custom?.find?.(({ _id }) => _id === this.customThemeId) ??
          themes?.custom?.find?.(({ sourceTheme }) => sourceTheme?._id === baseThemeId);
        if (customTheme && selectedTheme) {
          this.addThemeKitIdToUrl(customTheme._id).then(() => {
            this.initiateValues(true);
          });
        }
        return themes;
      },
    },
    hasAutoLogo: {
      query: GET_AUTO_LOGO,

      update(data) {
        return data.getAutoLogo;
      },
    },
  },
  data() {
    return {
      mainColor: null,
      themeColors: null,
      selectedRadius: null,
      selectedFont: null,
      selectedSecondaryFont: null,
      showBackdrop: false,
      loaded: false,
      name: THEME_NAME_FALLBACK,
      loadFontsFromAccount: null,
      imageModalName: MODAL_NAME,
      images: [],
      allTemplateImages: [],
      image: null,
      logo: null,
      language: 'en',
      url: null,
      urlLoading: false,
      fonts: null,
      availableFonts: [],
      colors: [],
      radiusValue: null,
    };
  },
  computed: {
    ...mapState(['account']),
    ...mapGetters(['getLocale', 'wizardPreferences', 'domains']),
    lottieLoading() {
      return `wizard_templates_loading_${this.getLocale}`;
    },
    color() {
      const params = new URLSearchParams(window.location.search);
      return this.mainColor || params.get('color');
    },
    theme() {
      return this.$route.query.theme;
    },
    needRecommendationInfo() {
      const { useCases } = this.wizardPreferences;
      return !useCases.length;
    },
    selectedMainTemplate() {
      const theme = this.customThemes?.base?.find?.(({ name }) => name === this.theme);
      return theme?.mainTemplate;
    },
    brandKit() {
      return {
        mainColor: this.mainColor,
        themeColors: this.themeColors,
        font: this.brandKitFont,
        radius: this.selectedRadius,
      };
    },
    templateThemeColors() {
      return ['Clean', 'Photographic'].includes(this.theme)
        ? get(this.selectedMainTemplate, 'template.style.palette.themeColors', [])
        : [];
    },
    brandKitFont() {
      if (!this.selectedFont) return null;
      const { key, value, name } = this.selectedFont;
      return { value: key, name: value || name };
    },
    customThemeId() {
      return this.$route.query.customTheme;
    },
    baseThemeId() {
      return this.$route.query.baseThemeId;
    },
    baseThemeIds() {
      return this.customThemes?.base?.map(({ _id }) => _id);
    },
    selectedCustomTheme() {
      const isAutoPersonalizeStep = this.$route.params.step === AUTO_PERSONALIZE;
      if (isAutoPersonalizeStep)
        return this.customThemes?.base.find(({ _id }) => _id === this.baseThemeId);
      const customTheme = this.customThemes?.custom.find(
        ({ _id, sourceTheme }) =>
          _id === this.customThemeId || sourceTheme?._id === this.baseThemeId,
      );
      return customTheme || this.customThemes?.base.find(({ _id }) => _id === this.baseThemeId);
    },
    businessName() {
      const name = (this.account.businessName || '').trim();
      return name?.length ? this.$t('onboarding.customTheme.themeName', { name }) : null;
    },
    payload() {
      const themeKit = this.buildThemeKit();
      return {
        ...themeKit,
        name: undefined,
        image: this.usedImage,
        language: this.language,
      };
    },
    isOriginalLogo() {
      return this.logo === this.selectedCustomTheme?.logo?.original;
    },
    usedImage() {
      return (
        this.images.find(this.imageNameComparer) ??
        this.allTemplateImages.find(this.imageNameComparer)
      );
    },
    currentLogo() {
      return this.usedImage?.url;
    },
    isOriginalTheme() {
      const params = new URLSearchParams(window.location.search);
      return Number(params.get('originalTheme' || '0'));
    },
  },
  validations: {
    name: {
      required: true,
    },
  },
  watch: {
    selectedMainTemplate(selected) {
      if (!selected) return;
      this.initiateValues();
      this.$nextTick(() => {
        this.updateDimensions();
      });
    },
    loaded() {
      this.$emit('loaded', this.loaded);
    },
  },
  methods: {
    ...mapMutations(['hideColorPicker']),
    handleFont(fonts) {
      this.availableFonts = fonts;
    },
    selectRadiusType(element) {
      const radius = element.type.replace('-type', '');
      this.selectedRadius = radius;
      brandkitTracker.trackChange('wizardRound', { value: radius });
    },
    selectFont(font) {
      this.selectedFont = font;
      this.$refs?.secondaryFont?.add?.(font);
      brandkitTracker.trackChange('wizardFont', { value: font });
    },
    selectSecondaryFont(font) {
      this.selectedSecondaryFont = font;
      this.$refs?.primaryFont?.add?.(font);
      brandkitTracker.trackChange('brandkitSecondary', { value: font });
    },
    reset() {
      this.initiateValues(true);
    },
    async nextPage() {
      this.$v.$touch();
      if (this.$v.$error) {
        return;
      }

      this.$emit('loading', true);
      try {
        await this.createNewTheme();
        await this.updateMainColorInUrl(this.mainColor);
        this.next(null, { color: this.mainColor });
      } catch (error) {
        console.error('unable to save brand kit: ', error);
      }
      this.$emit('loading', false);
    },
    buildThemeKit() {
      const fonts = [this.selectedFont?.key, this.selectedSecondaryFont?.key || 'open-sans'];
      return {
        fonts,
        rounding: this.selectedRadius || null,
        name: this.name,
        colors: {
          mainColor: this.mainColor,
          secondaryColors: getPaletteColors(this.mainColor).slice(1),
          themeColors: this.themeColors,
        },
      };
    },
    async createNewTheme() {
      const themeKit = this.buildThemeKit();
      try {
        const isBaseCustomThemeId = this.baseThemeIds.includes(this.selectedCustomTheme?._id);
        const customThemeId = isBaseCustomThemeId
          ? this.customThemeId
          : this.selectedCustomTheme?._id;
        const {
          data: { result },
        } = await this.$apollo.mutate({
          mutation: UPSERT_CUSTOM_THEME,
          variables: {
            id: customThemeId,
            name: this.name,
            sourceThemeName: this.theme,
            themeKit,
            hidden: false,
            logo: this.usedImage,
            createdFromWizard: true,
          },
        });
        if (result) {
          await this.addThemeKitIdToUrl(result._id);
        }
      } catch (e) {
        console.error('Cannot save custom theme');
        this.$notify({
          type: 'error',
          message: this.$t('notifications.saveError'),
        });
      }
    },
    async updateMainColorInUrl(mainColor) {
      const params = new URLSearchParams(window.location.search);
      const decodedColor = decodeURIComponent(params.get('color'));
      if (!mainColor && decodedColor !== mainColor) {
        this.setQueryStringParameter('color', decodedColor);
      }
    },
    async addThemeKitIdToUrl(customTheme) {
      const isAutoPersonalizeStep = this.$route.params.step === AUTO_PERSONALIZE;
      if (this.customThemeId !== customTheme && !isAutoPersonalizeStep) {
        this.setQueryStringParameter('customTheme', customTheme);
      }
    },
    setQueryStringParameter(name, value) {
      const params = new URLSearchParams(window.location.search);
      params.set(name, value);
      window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
    },
    onBackdropVisibilityChange(show) {
      this.showBackdrop = show;
    },
    onBackdropClick() {
      this.$refs.brandKitPalette.hideColorPicker();
    },
    onPaletteChange(colors) {
      const [main, ...secondaries] = colors;
      this.mainColor = main;
      this.themeColor = secondaries;
    },
    setThemeColors() {
      const { themeColors = [] } = this.selectedCustomTheme?.themeKit?.colors;
      this.themeColors = JSON.parse(JSON.stringify(themeColors));
    },
    setMainColor() {
      if (this.colors.length > 0) {
        this.mainColor = this.colors[0];
        return;
      }

      const params = new URLSearchParams(window.location.search);
      const decodedColor = decodeURIComponent(params.get('color') || '');
      if (!this.mainColor && decodedColor) {
        this.mainColor = decodedColor;
      } else if (!this.mainColor && !decodedColor) {
        this.mainColor = this.selectedCustomTheme.themeKit.colors.mainColor;
      }
    },
    setSelectedFont() {
      const params = new URLSearchParams(window.location.search);
      const decodedMainFont = decodeURIComponent(params.get('mainFont') || '');
      const decodedSecFont = decodeURIComponent(params.get('secondaryFont') || '');

      if (decodedMainFont) {
        this.selectedFont = { key: decodedMainFont };
      }

      if (decodedSecFont) {
        this.selectedSecondaryFont = { key: decodedSecFont };
        return;
      }

      const [primary = 'open-sans', secondary = 'open-sans'] =
        this.fonts || this.selectedCustomTheme?.themeKit.fonts || [];
      this.selectedFont = { key: primary };
      this.selectedSecondaryFont = { key: secondary };
    },
    setSelectedRadius() {
      const params = new URLSearchParams(window.location.search);
      const decodedRadius = decodeURIComponent(params.get('rounding') || '');

      if (decodedRadius) {
        this.selectedRadius = decodedRadius;
        return;
      }

      if (this.radiusValue) {
        this.selectedRadius = this.radiusValue;
        return;
      }

      const { rounding = 'none' } = this.selectedCustomTheme?.themeKit || {};
      this.selectedRadius = rounding;
    },
    setSelectedLogo(reset) {
      const { original, current } = this.selectedCustomTheme?.logo || {};
      this.logo = reset ? original : current ?? original;
      if (!this.isOriginalLogo) {
        this.image = this.usedImage;
      }
    },
    setCustomThemeName() {
      const themeName = this.customThemeId
        ? this.selectedCustomTheme.name
        : this.businessName || THEME_NAME_FALLBACK;
      this.name = themeName;
    },
    setAutoLogo() {
      const lastDomain = this.domains.slice(-1)[0];
      if (!lastDomain) return;

      if (!this.hasAutoLogo) return;

      const autoLogoImage = this.images.find((image) => {
        return image.name.includes(lastDomain.domain);
      });

      if (!autoLogoImage) return;

      this.onUseImage(autoLogoImage);
      track('detected-logo', { domain: lastDomain.domain, autoLogoImage });
    },
    initiateValues(reset) {
      this.setCustomThemeName(reset);
      this.setMainColor(reset);
      this.setThemeColors(reset);
      this.setSelectedFont(reset);
      this.setSelectedRadius(reset);
      this.setSelectedLogo(reset);
      this.setAutoLogo();
      this.loaded = true;
    },
    showImageManager() {
      this.$modal.show(this.imageModalName);
    },
    onUseImage(image) {
      this.image = image;
      this.logo = this.getNameFromURL(image?.url);
    },
    clearLogo() {
      this.image = null;
      this.setSelectedLogo(true);
      track('delete-logo');
    },
    imageNameComparer({ url }) {
      // keep the original template logo, when click on original style theme
      if (this.isOriginalTheme) this.setSelectedLogo(true);

      const pureName = getNameFromURL(url);
      const pureLogo = getNameFromURL(this.logo);

      return pureName && pureLogo && pureName === pureLogo;
    },
  },
};
