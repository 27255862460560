<template lang="pug">
.color-chip(:class="{ selected }" @click="handleColorClick()")
  .color-chip-wrapper
    .color-chip-preview(:style="style")
    .color-chip-hex {{ colorInHex }}
</template>
<script>
  export default {
    props: {
      colorInHex: { type: String, required: true },
      selected: { type: Boolean, default: false },
      loading: { type: Boolean, default: true },
    },
    computed: {
      style() {
        return {
          backgroundColor: this.colorInHex,
        };
      },
    },
    methods: {
      handleColorClick() {
        if (this.loading) return;

        this.$emit('click', this.colorInHex);
      },
    },
  };
</script>
<style lang="sass">
  @import "@/sass/variables/_colors.sass"
  .color-chip
    min-width: 128px
    padding: 8px
    border: 2px solid #E3E5E8
    border-radius: 8px
    cursor: pointer
    transition: all 0.2s
    &.selected
      border: 2px solid $om-orange
      box-shadow: 0px 4px 10px 0px rgba(237, 90, 41, 0.10), 0px 4px 16px 0px rgba(237, 90, 41, 0.04)
    &-wrapper
      display: flex
      gap: 8px
    &-preview
      height: 28px
      width: 28px
      border-radius: 4px
      border: 1px solid #E3E5E8
    &-hex
      display: flex
      align-items: center
      font-size: 16px
      font-weight: 700
      text-transform: uppercase
</style>
